import 'tailwindcss/tailwind.css';
import React, { Fragment } from 'react';
import { Intro } from '../components/Intro';
import SEO from '../components/seo';
import Auth from '../components/auth';
import withAuth from '../components/auth/hoc/withauth';

function IndexPage() {
  return (
    <Fragment>
      <SEO
        keywords={[
          `Cloud Shuttle`,
          `Status Page`,
        ]}
        title="Status Page by Cloud Shuttle!"
      />
      <Auth />
      <Intro />
    </Fragment>
  );
}

export default withAuth(IndexPage);
