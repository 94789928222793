import { useRouter } from 'next/router';
import updateUser from '../../../graphql/updateUser';

import { API } from 'aws-amplify';
export default function Final(props) {
  const router = useRouter();
  const user = props.user;
  const formState = props.formState;
  async function addUser(formstate, user) {
    console.log('formstate,', formstate);
    try {
      // await API.graphql({
      //   query: updateCompany,
      //   variables: {
      //     domain: user['username'].split('@')[1],
      //     company_name: formstate.company_name,
      //     website: formstate.website,
      //   },
      //   authMode: 'AMAZON_COGNITO_USER_POOLS',
      // });
      // const messages = 'New company created!';
      // console.log(messages);
      await API.graphql({
        query: updateUser,
        variables: {
          email: user.attributes.email,
          first_name: formstate.first_name,
          last_name: formstate.last_name,
          user_id: user.attributes.sub,
          company_id: user.attributes['custom:companyId'],
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });

      const message = 'Updated cognito user!';

      return message;
    } catch (err) {
      console.log({ err });
      return err;
    }
  }

  addUser(formState, user);
  router.push('/status');
}
