import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import Image from 'next/image';

import { useRouter } from 'next/router';
const NOTSIGNIN = 'You are NOT logged in';
const SIGNEDIN = 'You have logged in successfully';
export default function Example({ setPage, user, setUser }) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const router = useRouter();
  const [message, setMessage] = useState('signin');
  const [session, setSession] = useState(null);
  const password = Math.random().toString(10) + 'Abc#';
  // useEffect(() => {
  //   verifyAuth();
  // }, []);
  const verifyAuth = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setMessage(SIGNEDIN);
        setSession(null);
      })
      .catch((err) => {
        console.error(err);
        setMessage(NOTSIGNIN);
      });
  };

  const signIn = ({ email }) => {
    Auth.signIn(email)
      .then((result) => {
        setSession(result);
      })
      .catch((e) => {
        if (e.code === 'UserNotFoundException') {
          setMessage('sign-up');
          signUp(email);
        } else if (e.code === 'UsernameExistsException') {
          signIn();
        } else {
          console.error(e);
        }
      });
  };
  const signUp = async (email) => {
    const result = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email: email,
      },
    }).then(() => signIn({ email }));
    return result;
  };
  const verifyOtp = (props) => {
    console.log('sessions', session, props.code);
    Auth.sendCustomChallengeAnswer(session, props.code)
      .then((user) => {
        setUser(user);
        setSession(null);
        if (message == 'sign-in') {
          router.push('/status');
        } else {
          // check if company is present if no

          setPage('info');
        }
      })
      .catch((err) => {
        console.log('errors', err);
      });
  };
  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                Welcome!
              </h2>
              <h4 className="mt-6 text-1xl tracking-tight text-gray-500">
                We’re glad you’re here! Please enter your E-mail address to get
                started!
              </h4>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                {!user && !session && (
                  <form
                    className="space-y-6"
                    onSubmit={(e) => {
                      clearErrors();
                      setMessage('sign-in');
                      handleSubmit(signIn)(e);
                    }}
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          {...register('email')}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                )}
                {!user && session && (
                  <form
                    className="space-y-6"
                    onSubmit={handleSubmit(verifyOtp)}
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Code
                      </label>
                      <div className="mt-1">
                        <input
                          {...register('code')}
                          id="code"
                          name="code"
                          type="code"
                          autoComplete="code"
                          required
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <Image
            className="w-full h-full "
            src="/images/loginImage.jpg"
            alt=""
            width={'480'}
            height={'340'}
          />
        </div>
      </div>
    </>
  );
}
