import gql from 'graphql-tag';

const mutation = gql`
  mutation (
    $email: String
    $first_name: String
    $last_name: String
    $user_id: String
  ) {
    updateUser(
      email: $email
      first_name: $first_name
      last_name: $last_name
      user_id: $user_id
    ) {
      email
    }
  }
`;
export default mutation;
