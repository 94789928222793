import React, { useState } from 'react';
import SignIn from './auth/registration/signin';
import Info from '../components/auth/registration/user_info';

import Final from '../components/auth/registration/final';
import { Container } from './container';
export default function Auth() {
  const [page, setPage] = useState('signin');
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    website: '',
  });

  const handleInputData = (input) => (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };
  const conditionalComponent = () => {
    switch (page) {
      case 'signin':
        return <SignIn setPage={setPage} user={user} setUser={setUser} />;

      case 'info':
        return (
          <Info
            user={user}
            setPage={setPage}
            handleFormData={handleInputData}
          />
        );
      case 'final':
        return <Final user={user} formState={formData} />;

      default:
        return <SignIn setPage={setPage} user={user} setUser={setUser} />;
    }
  };

  return <Container>{conditionalComponent()}</Container>;
}
